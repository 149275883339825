import React, {createContext, ReactElement, ReactNode, useState} from 'react';
import {LoadingIndicator} from '../../components/loading-indicator';

export const GlobalIndicatorStateContext = createContext<
    GlobalLoadingIndicatorState | undefined
>(undefined);

interface GlobalIndicatorContextType {
    setLoadingIndicatorText: (text: string) => void;
    showLoadingIndicator: () => void;
    hideLoadingIndicator: () => void;
}

export const GlobalIndicatorContext = createContext<
    GlobalIndicatorContextType | undefined
>(undefined);

interface GlobalIndicatorProviderProps {
    children: ReactNode;
    initialState: GlobalLoadingIndicatorState;
}

export interface GlobalLoadingIndicatorState {
    text?: string;
    show: boolean;
    opacity?: number;
    color?: string;
}

export function GlobalLoadingIndicatorProvider({
    children,
    initialState,
}: GlobalIndicatorProviderProps): ReactElement<GlobalIndicatorProviderProps> {
    const [currentText, setText] = useState(initialState.text);
    const [visibility, setVisibility] = useState(initialState.show);

    const show = (): void => {
        setVisibility(true);
    };

    const hide = (): void => {
        setVisibility(false);
    };

    return (
        <GlobalIndicatorContext.Provider
            value={{
                setLoadingIndicatorText: setText,
                showLoadingIndicator: show,
                hideLoadingIndicator: hide,
            }}
        >
            {visibility && (
                <LoadingIndicator
                    info={currentText}
                    opacity={initialState.opacity}
                    color={initialState.color}
                />
            )}
            {children}
        </GlobalIndicatorContext.Provider>
    );
}

/**
 * Exposes 3 functions
 * setLoadingIndicatorText: Modifies the loading indicator text, it does not modify the visibility.
 * showLoadingIndicator: Shows the loading indicator, if the Loading Indicator is already visible it does nothing.
 * hideLoadingIndicator: Hides the loading indicator, if the Loading Indicator is already hidden it does nothing.
 */
export function useGlobalLoadingIndicator(): GlobalIndicatorContextType {
    const ctx = React.useContext(GlobalIndicatorContext);
    if (ctx === undefined) {
        throw new Error(
            'useGlobalLoadingIndicator must be used within GlobalLoadingIndicatorProvider'
        );
    }
    return ctx;
}
