import {css} from 'styled-components';

export const typographyCommons = css`
    font-family: ${({theme}) => theme.fontFamily};
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${({theme}) => theme.colors.ballena};
`;

export const typographyDisplay1Styles = css`
    ${typographyCommons}
    font-size: ${({theme}) => theme.typography.Display1.fontSize};
    font-weight: ${({theme}) => theme.typography.Display1.fontWeight};
    line-height: ${({theme}) => theme.typography.Display1.lineHeight};
`;

export const typographyDisplay2Styles = css`
    ${typographyCommons}
    font-size: ${({theme}) => theme.typography.Display2.fontSize};
    font-weight: ${({theme}) => theme.typography.Display2.fontWeight};
    line-height: ${({theme}) => theme.typography.Display2.lineHeight};
`;

export const typographyDisplay3Styles = css`
    ${typographyCommons}
    font-size: ${({theme}) => theme.typography.Display3.fontSize};
    font-weight: ${({theme}) => theme.typography.Display3.fontWeight};
    line-height: ${({theme}) => theme.typography.Display3.lineHeight};
`;

export const typographyHeading1Styles = css`
    ${typographyCommons}
    font-size: ${({theme}) => theme.typography.Heading1.fontSize};
    font-weight: ${({theme}) => theme.typography.Heading1.fontWeight};
    line-height: ${({theme}) => theme.typography.Heading1.lineHeight};
`;

export const typographyHeading2Styles = css`
    ${typographyCommons}
    font-size: ${({theme}) => theme.typography.Heading2.fontSize};
    font-weight: ${({theme}) => theme.typography.Heading2.fontWeight};
    line-height: ${({theme}) => theme.typography.Heading2.lineHeight};
`;

export const typographyHeading3Styles = css`
    ${typographyCommons}
    font-size: ${({theme}) => theme.typography.Heading3.fontSize};
    font-weight: ${({theme}) => theme.typography.Heading3.fontWeight};
    line-height: ${({theme}) => theme.typography.Heading3.lineHeight};
`;

export const typographyHeading4Styles = css`
    ${typographyCommons}
    font-size: ${({theme}) => theme.typography.Heading4.fontSize};
    font-weight: ${({theme}) => theme.typography.Heading4.fontWeight};
    line-height: ${({theme}) => theme.typography.Heading4.lineHeight};
`;

export const typographyLabelStyles = css`
    ${typographyCommons}
    font-size: ${({theme}) => theme.typography.Label.fontSize};
    font-weight: ${({theme}) => theme.typography.Label.fontWeight};
    line-height: ${({theme}) => theme.typography.Label.lineHeight};
`;

export const typographyTitleBodyStyles = css`
    ${typographyCommons}
    font-size: ${({theme}) => theme.typography.TitleBody.fontSize};
    font-weight: ${({theme}) => theme.typography.TitleBody.fontWeight};
    line-height: ${({theme}) => theme.typography.TitleBody.lineHeight};
`;

export const typographyLinkBodyStyles = css`
    ${typographyCommons}
    font-size: ${({theme}) => theme.typography.LinkBody.fontSize};
    font-weight: ${({theme}) => theme.typography.LinkBody.fontWeight};
    line-height: ${({theme}) => theme.typography.LinkBody.lineHeight};
`;

export const typographyBodyStyles = css`
    ${typographyCommons}
    font-size: ${({theme}) => theme.typography.Body.fontSize};
    font-weight: ${({theme}) => theme.typography.Body.fontWeight};
    line-height: ${({theme}) => theme.typography.Body.lineHeight};
`;

export const typographySmallBodyStyles = css`
    ${typographyCommons}
    font-size: ${({theme}) => theme.typography.SmallBody.fontSize};
    font-weight: ${({theme}) => theme.typography.SmallBody.fontWeight};
    line-height: ${({theme}) => theme.typography.SmallBody.lineHeight};
`;

export const typographyLargeBodyStyles = css`
    ${typographyCommons}
    font-size: ${({theme}) => theme.typography.LargeBody.fontSize};
    font-weight: ${({theme}) => theme.typography.LargeBody.fontWeight};
    line-height: ${({theme}) => theme.typography.LargeBody.lineHeight};
`;

export const typographyMediumBodyStyles = css`
    ${typographyCommons}
    font-size: ${({theme}) => theme.typography.MediumBody.fontSize};
    font-weight: ${({theme}) => theme.typography.MediumBody.fontWeight};
    line-height: ${({theme}) => theme.typography.MediumBody.lineHeight};
`;
