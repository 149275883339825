// eslint-disable-next-line import/named
import {DefaultTheme} from 'styled-components';

const ROOT_FONT_SIZE = 16;

const pxToRem = (size: number): string => `${size / ROOT_FONT_SIZE}rem`;

/**
 * Fonoma's base theme. Stores common constants used in our styled components
 *
 * As a general rule: Numerical values (like font sizes should be stored as numbers).
 */
export const FonomaBaseTheme: DefaultTheme = {
    fontFamily: '"Inter", sans-serif',
    fontSizes: {
        tinny: 12,
        small: 14,
        medium: 16,
        xMedium: 20,
        large: 24,
        xLarge: 32,
        xxLarge: 40,
        xxxLarge: 48,
        xxxxxLarge: 64,
    },
    fontMeasureUnit: 'px',
    intercomZIndex: 2147483001,
    globalLetterSpacing: 0.5,
    unitsOfMeasure: {
        px: 'px',
    },
    desktopMeasures: {
        centeredContentWidth: 1128,
        headerHeight: 600,
        navBarHeight: 76,
        homeNavBarHeight: 76,
        footerHeight: 60,
        cardWidth: 365,
        cardHeight: 436,
    },
    mobileMeasures: {
        navBarHeight: 64,
        homeNavBarHeight: 64,
        footerHeight: 60,
        headerHeight: 464,
        cardWidth: 256,
        cardHeight: 504,
    },
    navBar: {
        backgroundColor: '#fafafa',
    },
    homeNavBar: {
        backgroundColor: '#ffffff',
    },
    logo: {
        backgroundImage: '/static/landings/miscellanea/fonoma_logo_desk.svg',
        padding: '1rem 0rem',
        paddingLogged: '1rem',
    },
    bodyBackgroundColor: '#EFEDE6',
    colors: {
        arroz: '#FFFFFF',

        ballena: '#002C48',
        tiburon: '#648090',
        delfin: '#98ABB5',
        atun: '#D9E0E4',
        caballa: '#F2F4F6',
        plancton: '#00A8B5',
        rio: '#B5E6F5',
        error: '#E35131',
        success: '#00CFAD',
    },
    spacing: {
        xxs: '4px',
        xs: '8px',
        s: '12px',
        m: '16px',
        l: '24px',
        xl: '32px',
        xxl: '40px',
        xxxl: '44px', //as spacing-xxl-2 in zeplin ¯\_(ツ)_/¯
        xxxxl: '48px',
    },
    typography: {
        Display1: {
            fontSize: pxToRem(64),
            fontWeight: 'bold',
            lineHeight: 1.06,
        },
        Display2: {
            fontSize: pxToRem(56),
            fontWeight: 'bold',
            lineHeight: 1.2,
        },
        Display3: {
            fontSize: pxToRem(48),
            fontWeight: 'bold',
            lineHeight: 1.13,
        },
        Heading1: {
            fontSize: pxToRem(40),
            fontWeight: 600,
            lineHeight: '48px',
        },
        Heading2: {
            fontSize: pxToRem(32),
            fontWeight: 600,
            lineHeight: '40px',
        },
        Heading3: {
            fontSize: pxToRem(24),
            fontWeight: 600,
            lineHeight: '32px',
        },
        Heading4: {
            fontSize: pxToRem(20),
            fontWeight: 600,
            lineHeight: '24px',
        },
        LargeBody: {
            fontSize: pxToRem(20),
            fontWeight: 'normal',
            lineHeight: '28px',
        },
        TitleBody: {
            fontSize: pxToRem(16),
            fontWeight: 600,
            lineHeight: '24px',
        },
        LinkBody: {
            fontSize: pxToRem(16),
            fontWeight: 'normal',
            lineHeight: '24px',
        },
        Body: {
            fontSize: pxToRem(16),
            fontWeight: 'normal',
            lineHeight: '24px',
        },
        Label: {
            fontSize: pxToRem(16),
            fontWeight: 300,
            lineHeight: '24px',
        },
        MediumBody: {
            fontSize: pxToRem(14),
            fontWeight: 'normal',
            lineHeight: '20px',
        },
        SmallBody: {
            fontSize: pxToRem(12),
            fontWeight: 'normal',
            lineHeight: '16px',
        },
    },
    homeBanner: {
        containerPadding: '4rem 0 4.5rem',
    },
    homeHeader: {
        marginLeft: 'unset',
    },
    reviewsIO: {
        widgetTextColor: '#002C48', //ballena
        carruselMargin: '8rem 0',
    },
    loadingIndicator: {
        animationPath: '/static/landings/animations/spinner.json',
    },
};
