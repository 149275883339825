module.exports ={
    "locales": [
        "en",
        "es"
    ],
    "defaultLocale": "es",
    "loadLocaleFrom": (lang, ns) =>
        // You can use a dynamic import, fetch, whatever. You should
        // return a Promise with the JSON file.
        import(`./locales/${lang}/${ns}.json`).then((m) => m.default),
    "pages": {
        "*": [
            "common",
            "metas",
            "landings",
            "footer",
            "nav_bar",
            "validation"
        ],
        "rgx:^/(fonoma|retope)/redeem_promo_code": [
            "redeem_promo_code_landing",
            "home_landing"
        ],
        "rgx:^/(fonoma|retope)/invite_landing": [
            "invite_landing",
            "home_landing"
        ],
        "rgx:^/(fonoma|retope)/home_landing": [
            "home_landing",
            "time_expressions"
        ],
    }
}