/* eslint-disable import/named */
// eslint-disable-next-line import/named
import {createGlobalStyle, css} from 'styled-components';

import {mediaQueries} from '../modules/common/styles/media-queries-breakpoints';
import {typographyBodyStyles} from '../modules/common/styles';

/**
 * Global styles that will be applied to all our pages
 */
// eslint-disable-next-line
export const FonomaGlobalStyle = createGlobalStyle<{}>`
  body {  
    ${typographyBodyStyles}
    ${(props) => css`
        margin: 0;
        padding-top: ${props.theme.desktopMeasures.navBarHeight}${props.theme.unitsOfMeasure.px};
        ${mediaQueries.lessThan('small')`
        padding-top: ${props.theme.mobileMeasures.navBarHeight}${props.theme.unitsOfMeasure.px};
      `};
    `}
  }
  
  div{
    box-sizing: border-box;
  }    
`;
