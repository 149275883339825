import {css} from 'styled-components';
import {transparentize} from 'polished';

export const shadowBottomLong = css`
    box-shadow: 0 10px 30px 0
        ${({theme}) => transparentize(0.85, theme.colors.ballena)};
`;

export const shadowBottomMedium = css`
    box-shadow: 0 5px 15px 0
        ${({theme}) => transparentize(0.85, theme.colors.ballena)};
`;

export const shadowBottomShort = css`
    box-shadow: 0 2px 6px 0
        ${({theme}) => transparentize(0.85, theme.colors.ballena)};
`;

export const shadowTopLong = css`
    box-shadow: 0 -2px 20px 0 ${({theme}) => transparentize(0.85, theme.colors.ballena)};
`;
