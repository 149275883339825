/**
 In case other conversions between color spaces need to be added...

 References...
 https://css-tricks.com/converting-color-spaces-in-javascript/
*/

/**
 * Function to return HEX conversion of alpha value
 * this can be used to add transparency to HEX color

 * params:
 * opacity: opacity to convert
 * opacity should be a number between 0 and 1
**/
export const convertAlphaToHex = (opacity: number): string => {
    if (opacity < 0 || opacity > 1) {
        throw new Error('Invalid alpha value');
    }

    return Math.round(opacity * 255).toString(16);
};
