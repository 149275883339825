/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/ban-types */
import isNode from 'detect-node';
import * as React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import {insertScript} from '../../modules/util/insert-script';
import {getUTMData} from '../../modules/util/utilities/get-utms';

/**
 * Interface for components that use analytics
 */
export interface WithAnalytics {
    analytics: Analytics;
}

/**
 * Wrapper class around segment's analytics
 */
export class Analytics {
    readonly CALLBACK_TIME = 100;

    constructor() {
        let analytics;

        // In the server we just create the queue, because we'll not be using any of the methods
        if (isNode) {
            analytics = [];
        } else {
            // Create a queue, but don't obliterate an existing one!
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            analytics = window.analytics = window.analytics || [];

            // This code was adapted from
            // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/

            // If the real analytics.js is already on the page return.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (analytics.initialize) return;

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            // If the snippet was invoked already, show an error.
            if (analytics.invoked) {
                throw new Error('Segment snippet included twice.');
            }
        }

        // A list of the methods in Analytics.js to stub.
        analytics.methods = ['identify', 'track', 'page', 'alias'];

        // Define a factory to create stubs. These are placeholders
        // for methods in Analytics.js so that you never have to wait
        // for it to load to actually record data. The `method` is
        // stored as the first argument, so we can replay the data.
        analytics.factory = function (method) {
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            return function (...args) {
                args.unshift(method);
                analytics.push(args);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return analytics;
            };
        };

        // For each of our methods, generate a queueing stub.
        for (let i = 0; i < analytics.methods.length; i++) {
            const key = analytics.methods[i];
            analytics[key] = analytics.factory(key);
        }

        // Add the version.
        analytics.SNIPPET_VERSION = '4.1.0';
    }

    /**
     * Loads analytics.js from segment's CDN.
     * @param options: Extra options.
     */
    load(options?: object): void {
        // Early return
        if (window.analytics.invoked) {
            return;
        }

        insertScript(
            'https://cdn.segment.com/analytics.js/v1/' +
                process.env.NEXT_PUBLIC_SEGMENT_API_KEY +
                '/analytics.min.js'
        );
        window.analytics._loadOptions = options;
        // Invoked flag, to make sure the snippet
        // is never invoked twice.
        window.analytics.invoked = true;
    }

    /**
     * Tracks an event.
     *
     * Returns a promise that resolves after CALLBACK_TIME
     *
     * See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track for params details
     *
     * @param event: Event's name.
     * @param properties: Event's properties.
     * @param options: Extra options.
     */
    track(event: string, properties?: object, options?: object): Promise<void> {
        const utms = getUTMData(window.location.href);
        properties = {
            ...properties,
            fnmClient: 'web',
        };

        if (Object.keys(utms).length !== 0) {
            properties = {
                ...utms,
                ...properties,
            };
        }

        window.analytics.track(event, properties, options);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(), this.CALLBACK_TIME);
        });
    }

    /**
     * Identifies an user.
     *
     * Returns a promise that resolves after CALLBACK_TIME
     *
     * See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify for params details
     *
     * @param userId: The database ID for the user (For segment this param is optional but we always use it)
     * @param traits: A dictionary of traits you know about the user, like their email or name.
     * @param options: Extra options.
     */
    identify(userId: string, traits?: object, options?: object): Promise<void> {
        window.analytics.identify(userId, traits, options);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(), this.CALLBACK_TIME);
        });
    }

    /**
     *
     * Records a page view
     *
     * Note: if you pass only one string to page it is assumed to be name. You must include a name to send a category.
     *
     * See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
     *
     * If you pass only one string to page it is assumed to be name.
     *
     * @param category: The category of the page.
     * @param name: The name of the page.
     * @param properties: A dictionary of properties of the page. Note: url, title,
     *                    referrer and path are collected automatically!
     * @param options: Extra options.
     */
    page(
        category?: string,
        name?: string,
        properties?: object,
        options?: object
    ): Promise<void> {
        properties = {
            ...properties,
            fnmClient: 'web',
        };

        if (category && !name) {
            name = category;
            category = undefined;
        }
        window.analytics.page(category, name, properties, options);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(), this.CALLBACK_TIME);
        });
    }

    /**
     *
     * Combines two previously unassociated user identities.
     * Aliasing is generally handled automatically when you identify a user.
     * However, some tools require an explicit alias call (i.e. MixPanel).
     *
     * See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#alias
     *
     * @param userId: The new user ID you want to associate with the user.
     * @param previousId: The previous ID that the user was recognized by.
     *                     This defaults to the currently identified user’s ID.
     * @param options: Extra options.
     */
    alias(
        userId: string,
        previousId?: string,
        options?: object
    ): Promise<void> {
        window.analytics.alias(userId, previousId, options);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(), this.CALLBACK_TIME);
        });
    }

    /**
     *
     * HOC that injects the Analytics object as a prop (named analytics).
     *
     * @param WrappedComponent
     */
    withAnalytics<P>(
        WrappedComponent: React.ComponentType<P>
    ): React.Component<P> {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const _object = this;

        class Wrapper extends React.Component<P> {
            render(): JSX.Element {
                return <WrappedComponent {...this.props} analytics={_object} />;
            }
        }

        // This necessary because when you apply a HOC to a component, the original component
        // is wrapped with a container component. That means the new component does not have any of the static methods
        // of the original component.
        // See https://reactjs.org/docs/higher-order-components.html#static-methods-must-be-copied-over
        return hoistNonReactStatics(
            Wrapper,
            WrappedComponent
        ) as unknown as React.Component<P>;
    }
}
