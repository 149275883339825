import React from 'react';
import {DefaultSeo} from 'next-seo';

/**
 * This component contains the default SEO configuration for all our pages (i.e. all pages have
 * this values unless you override some of them)
 * TODO: Some vars need review (google-site-verification, metas:og-description, etc)
 * @constructor
 */
export const FonomaDefaultSeo = (): JSX.Element => {
    const baseURL = process.env.NEXT_PUBLIC_ASSETS_URL.replace('/assets', '');
    const PROJECT_NAME = process.env.NEXT_PUBLIC_PROJECT_NAME_DISPLAY;

    return (
        <DefaultSeo
            facebook={{
                appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
            }}
            openGraph={{
                type: 'website',
                images: [
                    {
                        url: `${baseURL}/home/v2/facebook-opengraph.png`,
                    },
                ],
                site_name: PROJECT_NAME,
            }}
            additionalMetaTags={[
                {
                    name: 'mobile-web-app-capable',
                    content: 'Yes',
                },
                {
                    name: 'apple-mobile-web-app-capable',
                    content: 'Yes',
                },
                {
                    name: 'apple-mobile-web-app-status-bar-style',
                    content: 'translucent-black',
                },
                {
                    name: 'apple-mobile-web-app-title',
                    content: `${PROJECT_NAME} Web`,
                },
                {
                    name: 'robots',
                    content: 'index, follow',
                },
            ]}
        />
    );
};
