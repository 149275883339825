/* eslint-disable import/named */
// eslint-disable-next-line import/named
import {createGlobalStyle, css} from 'styled-components';

import {mediaQueries} from '../modules/common/styles/media-queries-breakpoints';
import {typographyBodyStyles} from '../modules/common/styles';

/**
 * Global styles that will be applied to all our pages
 */
// eslint-disable-next-line
export const TeloaGlobalStyle = createGlobalStyle<{}>`
  html {
    scroll-behavior: smooth;
  }
  body {  
    ${typographyBodyStyles}
    ${() => css`
        margin: 0;
        ${mediaQueries.lessThan('small')`
      `};
    `}
  }
  
  div{
    box-sizing: border-box;
  }    
`;
