import React, {useEffect} from 'react';
import {insertScript} from '../../modules/util/insert-script';

interface ReviewsIoContextState {
    carouselLoaded: boolean;
    ratingBarLoaded: boolean;
}

interface Props {
    children: React.ReactNode;
}

const {Provider, Consumer} = React.createContext({
    carouselLoaded: false,
    ratingBarLoaded: false,
} as ReviewsIoContextState);

const ReviewsIoContextProvider = (props: Props): JSX.Element => {
    const [carouselLoaded, setCarouselLoaded] = React.useState(false);
    const [ratingBarLoaded, setRatingBarLoaded] = React.useState(false);

    useEffect(() => {
        if (!window.carouselInlineWidget) {
            insertScript(
                'https://widget.reviews.io/carousel-inline-iframeless/dist.js?_t=2023052213',
                () => {
                    setCarouselLoaded(true);
                }
            );
        }
        insertScript(
            'https://widget.reviews.io/modern-widgets/rating-bar.js',
            () => {
                setRatingBarLoaded(true);
            }
        );
    }, []);

    const {children} = props;
    return (
        <Provider
            value={{
                carouselLoaded: carouselLoaded,
                ratingBarLoaded: ratingBarLoaded,
            }}
        >
            {children}
        </Provider>
    );
};

export {ReviewsIoContextProvider, Consumer as ReviewsIoContextContextConsumer};
