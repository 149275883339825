import {UTM_NAMES} from '../constants';

/**
 * Return the utm presents in the url in object form
 * @param url Current url ex www.fonoma.com?utm_source=facebook&utm_campaign=nauta_extra_0
 * @returns An object with key utm_names if exits in the url ex. {utm_source: "facebook", utm_campaign: "nauta_extra_0"}
 */
interface IUtmObj {
    [key: string]: string;
}

export const getUTMData = (url: string): IUtmObj => {
    const _url = new URL(url);
    const urlSearchParams = _url.searchParams;
    const data = {};
    for (const key of UTM_NAMES) {
        const value = urlSearchParams.get(key);
        if (value) {
            data[key] = value;
        }
    }
    return data;
};
