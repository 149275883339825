export const insertScript = (src: string, onLoad?: () => void): void => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = src;
    // Insert our script next to the first script element.
    const first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
    if (onLoad) {
        script.onload = onLoad;
    }
};
