import {analytics} from '../analytics/analytics';

export function handleCookiebotResponse() {
    // Check if the user accepted the all cookies.
    // TODO: Add granularity to the segment's integrations
    // we load depending on the user's cookies. For example,
    // if the user only accepted statistics cookies, load amplitude,
    // but if they also accepted marketing load Google, Facebook and the rest.
    // TODO: We should try to load intercom no matter what, however,
    // they will set their own cookies and this could cause trouble.
    if (
        window.Cookiebot &&
        window.Cookiebot.consented &&
        window.Cookiebot.consent.preferences &&
        window.Cookiebot.consent.statistics &&
        window.Cookiebot.consent.marketing
    ) {
        analytics.load();
    }
    window.removeEventListener('CookiebotOnAccept', handleCookiebotResponse);
}
